<template>
    <div class="flex_layout_row">
        <div class="flex_left left_menu">
            <div class="logo">
                <img src="/img/logo.jpg" class="logo_icon">
            </div>
            <div @click="to(menu.path)" :class="['menu_label', getOn(menu)]" :key="idx" v-for="(menu, idx) in adminRoutes">
                <div class="menu_name">
                    <component :is="$antIcons[menu.icon]" />
                    {{menu.name}}
                </div>
            </div>
        </div>
        <div class="flex_body">
            <div class="flex_layout">
                <div class="flex_top admin_topbar">
                    <div class="user_info">
                        <div class="avatar">
                            <a-avatar class="primary">
                                <template #icon>
                                   <component :is="$antIcons['UserOutlined']" />
                                </template>
                            </a-avatar>
                        </div>
                        <div class="username">Hi, welcome, {{getUsername}}</div>
                        <a-space>
                            <a-button @click="changePassword" class="primary btn" shape="round" size="small">
                                <template #icon>
                                   Update password
                                </template>
                            </a-button>
                            <a-button @click="logout" danger shape="round" size="small">
                                <template #icon>
                                <component :is="$antIcons['PoweroffOutlined']" />
                                </template>
                            </a-button>
                        </a-space>
                    </div>
                </div>
                <div class="flex_body">
                    <router-view />
                </div>
                <div class="flex_bottom">AMGT copyright</div>
            </div>
        </div>
        <a-modal v-model:visible="visible" title="Update password" okText="Submit" @ok="onOk"> 
            <a-spin :spinning="spinning">
                <a-form :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-item label="Old password">
                        <a-input v-model:value="formData.old_password" type="password" placeholder="Old password" />
                    </a-form-item>                                                  
                    <a-form-item label="New password">
                        <a-input v-model:value="formData.new_password" type="password" placeholder="New password" />
                    </a-form-item> 
                </a-form>
            </a-spin>
        </a-modal>
    </div>
</template>
<script>
import { message } from 'ant-design-vue'
import adminRoutes from '@/config/admin.routes'
import storage from '@/utils/storage'
import { updateAdmin } from '@/apis/admin'
export default {
    name: 'Index',
    data(){
        return {
            adminRoutes,
            visible: false,
            formData: {
                old_password: '',
                new_password: '',
            },
            labelCol: { span: 6 },
            wrapperCol: { span: 12 },
            spinning: false
        }
    },
    methods: {
        to(path){
            this.$router.push({path})
        },
        changePassword(){
            this.visible = true    
        },
        async onOk(){
            this.spinning = true
            const res = await updateAdmin(this.formData)
            this.spinning = false
            if(res){
                message.success('success')
                this.visible = false
            }
        },
        async logout(){
            storage.clear()
            message.success('success')
            setTimeout(() => {
                this.$router.push({path: '/login'})
            }, 1000)
        }
    },
    computed: {
        getOn(){
            return menu => {
                return {on : this.$route.path.indexOf(menu.path) > -1}
            }
        },
        getUsername(){
            return storage.getLocalData('username')
        }
    }
}
</script>
<style lang="stylus" scoped>
.flex_bottom
    text-align center
    background #fff
    color #999
</style>