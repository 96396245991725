const adminRoutes = [
    {
        name: 'Dashboard',
        path: '/manage/admin/dashboard',
        icon: 'AreaChartOutlined'
    },
    {
        name: 'Order',
        path: '/manage/admin/order',
        icon: 'FileTextOutlined'
    },
    {
        name: 'Product',
        path: '/manage/admin/product',
        icon: 'InsertRowRightOutlined'
    },
    {
        name: 'Recommended',
        path: '/manage/admin/recommended',
        icon: 'LikeOutlined'
    },
    {
        name: 'Personnel',
        path: '/manage/admin/personnel',
        icon: 'IdcardOutlined'
    },    
    {
        name: 'Task',
        path: '/manage/admin/task',
        icon: 'ApartmentOutlined'
    },
    {
        name: 'Website',
        path: '/manage/admin/website',
        icon: 'DesktopOutlined'
    },
    {
        name: 'Administrator',
        path: '/manage/admin/administrator',
        icon: 'TeamOutlined'
    }
]
export default adminRoutes